<template>
  <b-modal
    id="modalCarrierServices"
    :title="$t(`${editing ? 'Editar' : 'Nuevo'} servicio`)"
    modal-class="dialog-900"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    @close="close()"
  >
    <div class="custom-switch my-1 d-flex">
      <div>
        <b-form-checkbox
        id="is_active"
        v-model="isActive"
        switch>
        </b-form-checkbox>
      </div>
      <div>
        <small class="font-weight-bold">{{ !isActive ? 'Activar servicio' : 'Inactivar servicio' }}</small>
      </div>
    </div>
    <b-tabs align="left" class="tab-primary" @activate-tab="activatedEvent" v-model="tabIndex">
      <b-tab>
        <template #title>
          <div class="tab-title">
            <span> {{$t('Condiciones generales')}} </span>
          </div>
        </template>
        <service-data-form
          :formTabData="formTab"
          @courthoursActiveFn="changeCourthHours"
          :labelTimerPick="labelTimerPicker"
          :tabActive="tabNumber"
          ref="formServicesData"
        /></b-tab>
      <b-tab>
        <template #title>
          <div>
            <span> {{$t('Retiros')}} </span>
          </div>
        </template>
        <special-conditions-form
          :formTab="formTab"
          ref="formSpecialCondition"
          @courthoursActiveFn="changeCourthHours"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <div>
            <span> {{$t('Información Seguros')}} </span>
          </div>
        </template>
        <information-insurance-form
        :formTab="formTab"
        ref="formInformationInsurance"
        />
      </b-tab>
    </b-tabs>
    <template #modal-footer>
      <b-button variant="warning" @click.prevent="ok" :disabled="!courthoursActive || loading.callingService">
        <b-spinner v-show="loading.callingService" small></b-spinner>
        {{$t('Guardar')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ServiceDataForm from './ModalServicesComponents/ServiceDataForm.vue'
import InformationInsuranceForm from './ModalServicesComponents/InformationInsuranceForm.vue'
import SpecialConditionsForm from './ModalServicesComponents/SpecialConditionsForm.vue'

export default {
  name: 'modal-carrier-services',
  props: ['formTab'],
  components: { ServiceDataForm, SpecialConditionsForm, InformationInsuranceForm },
  data() {
    return {
      loading: { callingService: false },
      courthoursActive: true,
      labelTimerPicker: '',
      specialConditionDataForm: {},
      tabNumber: 0,
      tabIndex: 0,
      editing: false,
      isActive: false
    }
  },
  watch: {
    formTab() {
      this.editing = !!this.formTab.id
      this.isActive  = this.formTab.is_active
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
    },
    onAccept() {
      this.loading.callingService = true
      const formService = this.$refs.formServicesData.getFormService()
      const formSpecial = this.$refs.formSpecialCondition.getFormSpecial()
      const formInformation = this.$refs.formInformationInsurance.getFormInsurance()
      
      const form = {
        ...formService,
        ...formSpecial,
        ...formInformation,
        is_active: this.isActive
      }
      if (this.editing) {
        this.updateService(form)
      } else {
        this.saveService(form)
      }
    },
    async ok() {
      const isValidSeviceData = await this.$refs.formServicesData.ok()
      const isValidSpecialCondition = await this.$refs.formSpecialCondition.ok()
      const isValidationInformation =  await this.$refs.formInformationInsurance.ok()
      if ([isValidSeviceData, isValidSpecialCondition, isValidationInformation].includes(false)) {
        this.$bvToast.toast('Por favor verifique las pestañas y complete los campos obligatorios', {
          title: 'Campos obligatorios sin completar',
          variant: 'danger',
          solid: true
        })
      }
      
      if (!isValidSeviceData) return this.tabIndex = 0
      else if (!isValidSpecialCondition) return this.tabIndex = 1
      else if (!isValidationInformation) return this.tabIndex = 2
      
      this.onAccept()
    },
    updateService(data) {
      const queryParams = {
        ...data
      }
      this.$store.dispatch('fetchService', { name: 'updateServicesCarrier', queryParams, params: { carrier_service_id: this.formTab.id }, onSuccess: this.successUpdateService, onError: () => this.loading.callingService = false })
    },
    successUpdateService() {
      this.loading.callingService = false
      const queryParams = { carrier_id: this.$route.params.id }
      this.$store.dispatch('fetchService', { name: 'getServicesCarrier', queryParams })
      this.$bvModal.hide('modalCarrierServices')
      this.$success(this.$t('msg-exito-actualizar'))
    },
    saveService(data) {
      try {
        const queryParams = {
          ...data
        }
        this.$store.dispatch('fetchService', { name: 'createServicesCarrier', queryParams, onSuccess: this.successSaveServices, onError: () => this.loading.callingService = false })
      } catch (error) {
        console.error(error)
        this.labelTimerPicker = this.$t('msg-horario-invalido')
      }
    },
    successSaveServices() {
      this.loading.callingService = false
      this.$store.dispatch('fetchService', { name: 'getServicesCarrier', queryParams: { carrier_id: this.$route.params.id } })
      this.$bvModal.hide('modalCarrierServices')
      this.$success(this.$t('msg-exito-guardar'))
    },
    changeCourthHours(bool) {
      this.courthoursActive = bool
    },
    activatedEvent (newT, prevT, bvEvent) {
      this.tabNumber = newT
    },
    close() {
      this.isActive = false
    }
  }
}
</script>
<style>
#modalCarrierServices .modal-content{
  background-color: #F8FAFB;
}
#modalCarrierServices .modal-footer{
    border: none;
}
.custom-switch > .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1E8E3E !important;
  background-color: #1E8E3E !important;
}
</style>