<template>
  <div class="bg-white p-1">
    <form-render
      :form.sync="form"
      :fields="fields" 
      containerButtonsClass="col-sm-12"
      ref="formRenderInformationInsurance"
      :key="formRenderInformationInsurance"
    >
    </form-render>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'

export default {
  props: {
    formTab: { type: Object }
  },
  data() {
    return {
      baseService: new BaseServices(this),
      fields: [],
      form: {},
      formRenderInformationInsurance: 0,
      aditionalInsuranceOpt: [{ id: 1, text: this.$t('No') }, { id: 2, text: this.$t('Si') }, { id: 3, text: this.$t('Obligatorio') }],
      payCompensationOpt: [{ id: 1, text: this.$t('No') }, { id: 2, text: this.$t('Si') }],
      howInsuranceOpt: []
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    editing() {
      return !!this.formTab?.id
    }
  },
  watch: {
    editing() {
      if (!this.editing) {
        this.form = {}
      }
    }
  },
  methods: {
    setInitialData() {
      this.getActivateType()
      this.fields = [
        { fieldType: 'FieldInput', name: 'insurance_included', label: 'Seguro incluido (neto)', containerClass: 'col-sm-12 col-md-6 container-info' },
        { fieldType: 'FieldRadio', name: 'additional_insurance', label: 'Seguro adicional', containerClass: 'col-sm-12 col-md-12 container-info mb-2', validation: 'required', align: 'h', options: this.aditionalInsuranceOpt, change: (name, value) => this.handleRadioChanges(name, value, ['additional_secure_activation', 'additional_safe_stop', 'how_activate_insurance_id']) },
        { fieldType: 'FieldInput', name: 'additional_secure_activation', label: '% seguro adicional', type: 'number', disabled: true, containerClass: 'col-sm-12 col-md-6 container-info mb-2' },
        { fieldType: 'FieldInput', name: 'additional_safe_stop', label: 'Tope seguro adicional', disabled: true, containerClass: 'col-sm-12 col-md-6 container-info mb-2' },
        { fieldType: 'FieldSelect', name: 'how_activate_insurance_id', label: 'Cómo activar seguro adicional', disabled: true, containerClass: 'col-sm-12 col-md-6 container-info mb-2', change: this.hadleInsuranceTypechange },
        { fieldType: 'FieldTextarea', name: 'secure_summary', label: 'Otras consideraciones del seguro', placeholder: 'Ingrese resumen', containerClass: 'col-sm-12 container-info mb-2'},
        { fieldType: 'FieldRadio', name: 'pay_even_accepted_compensation', label: 'Cobro independientemente indemnización aceptada', containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required', align: 'h', options: this.payCompensationOpt, change: (name, value) => this.handleRadioChanges(name, value, ['independent_compensation_collection_accepted']) },
        { fieldType: 'FieldInput', name: 'independent_compensation_collection_accepted', placeholder: 'Ingrese cobro independientemente...', containerClass: 'col-sm-12 col-md-6 container-info', dependency: 'pay_even_accepted_compensation' }
      ]
      this.setData()
    },
    setData() {
      if (this.editing) {
        this.form = {
          insurance_included: this.formTab.insurance_included,
          additional_insurance: this.aditionalInsuranceOpt.find(data => data.text === this.formTab.additional_insurance),
          additional_secure_activation: this.formTab.additional_secure_activation,
          additional_safe_stop: this.formTab.additional_safe_stop,
          secure_summary: this.formTab.secure_summary
        }
        if (['Obligatorio', 'Si'].includes(this.formTab.additional_insurance)) {
          this.fields[this.fields.findIndex(({name}) => name === 'additional_secure_activation')].disabled = false
          this.fields[this.fields.findIndex(({name}) => name === 'additional_safe_stop')].disabled = false
        }
        if (this.formTab.independent_compensation_collection_accepted) {
          this.form.pay_even_accepted_compensation = { id: 2, text: this.$t('Si') }
          this.form.independent_compensation_collection_accepted = this.formTab.independent_compensation_collection_accepted
          this.fields[this.fields.findIndex(({name}) => name === 'independent_compensation_collection_accepted')].disabled = false
        } else {
          this.form.pay_even_accepted_compensation = { id: 1, text: this.$t('No') }
        }
      }
    },
    async ok() {
      return await this.$refs.formRenderInformationInsurance.onlyCheckForm()
    },
    getFormInsurance() {
      const form = {
        ...this.form,
        additional_insurance: this.form.additional_insurance.text,
        independent_compensation_collection_accepted: this.form.pay_even_accepted_compensation.id === 2 ? this.form.independent_compensation_collection_accepted : '',
        pay_even_accepted_compensation: this.form.pay_even_accepted_compensation.text
      }
      if (this.form?.how_activate_insurance_id) form.how_activate_insurance_id = this.form?.how_activate_insurance_id?.id
      Object.keys(form).forEach(key => {
        if (form[key] === null) {
          delete form[key]
        }
      })
      return form
    },
    handleRadioChanges(name, value, target) {
      target.forEach((key) => {
        const index = this.fields.findIndex(el => el.name === key)
        const isFalse = value?.id === 1
        this.fields[index].disabled = isFalse
        if (isFalse && this.form[key]) this.form[key] = null
      })      
    },
    getActivateType() {
      this.baseService.callService('getInsuranceActivateType')
        .then(res => {
          const response = res.data.options
          this.howInsuranceOpt = response.map((el) => ({...el, text: el.name}))
          this.fields[this.m_getElementIndex(this.fields, 'name', 'how_activate_insurance_id')].options = this.howInsuranceOpt
          if (this.editing && this.formTab.how_activate_insurance_id) {
            this.addignEditInsuranceData()
          }
          this.formRenderInformationInsurance++
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    addignEditInsuranceData() {
      const value = this.howInsuranceOpt.find(data => data.id === this.formTab.how_activate_insurance_id)
      this.form.how_activate_insurance_id = value
      this.fields[this.fields.findIndex(({name}) => name === 'how_activate_insurance_id')].disabled = false
      this.hadleInsuranceTypechange('', value)            
      if (this.formTab.how_activate_insurance_data) this.form.how_activate_insurance_data = this.formTab.how_activate_insurance_data
    },
    hadleInsuranceTypechange(name, value) {
      this.deleteElement('how_activate_insurance_data')
      if (!value) return
      if (value.is_other_option) {
        const index = this.m_getElementIndex(this.fields, 'name', 'how_activate_insurance_id')
        this.fields.splice(index + 1, 0, {
          fieldType: 'FieldInput', label: this.$t('Información seguro adicional'), name: 'how_activate_insurance_data', containerClass: 'col-md-6 container-info col-sm-12', validation: 'required'
        })
      }
    },
    deleteElement(fieldName) {
      delete this.form.how_activate_insurance_data
      const indexAct = this.m_getElementIndex(this.fields, 'name', fieldName)
      if (indexAct !== -1) this.fields.splice(indexAct, 1)
    }
  }
}
</script>

<style>

</style>