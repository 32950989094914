<template>
  <div>
    <b-card>
      <div class="mt-2">
        <form-render
          class="mb-2"
          :fields="fields"
          :key="formRenderGenerals"
          :form.sync="form"
          @send="updateOrSaveCarrierDetails"
          ref="formRenderGenerals"
          containerButtonsClass="col-sm-12 col-md-3 container-button"
        >
          <template #space />
          <template #titleComercial>
            <h5>{{$t("Datos comerciales")}}</h5>
          </template>

          <template #logoCourier>
            <b-media no-body class="flex flex-column align-items-start justify-content-center">
              <b-media-aside class="container-avatar">
                <span class="overimage" v-show="loading.uploading"><feather-icon icon="LoaderIcon" size="3x" class="spinner "/></span>
                <span>
                  <img
                    ref="previewEl"
                    :src="avatar ? avatar :  require('@/assets/images/support/truck.svg')"
                    alt="logo-courier"
                    style="border-radius: 5px;border: 2px solid #CDCDCD;"
                    class="logo-preview"
                    >
                </span>
              </b-media-aside>
              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->

                <b-card-text>{{$t('Imagen (PNG) Tamaño sugerido 200px por 100px')}}</b-card-text>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  size="sm"
                  :disabled="loading.uploading"
                  class="mb-75 mr-75"
                  @click="$refs.refInputEl.$el.click()"
                >
                  {{$t('Cargar logotipo')}}
                </b-button>
                <b-form-file
                  ref="refInputEl"
                  v-model="logoFile"
                  accept=".jpg, .png, .gif, jpeg"
                  :hidden="true"
                  plain
                  @input="inputImageRenderer"
                />
              </b-media-body>
            </b-media>
          </template>

        </form-render>
        <b-button
          variant="warning"
          class="push-right col-sm-12 col-md-2"
          @click="send"
          >Guardar</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarriersService from '../carriers.service'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import BaseServices from '@/store/services/index'
import { ref } from '@vue/composition-api'

export default {
  name: 'generals',
  data() {
    return {
      form: {},
      actions: null,
      logoFile: null,
      preview: null,
      baseService: new BaseServices(this),
      fields: [],
      formRenderGenerals: 0,
      carrier_id: this.$route.params.id,
      carriersService: new CarriersService(this),
      optionsPlatform: [{ id: true, text: 'Si' }],
      avatar: null,
      loading: {
        form: true,
        image: true,
        uploading: false
      },
      companyCarrierOptions: Object.freeze([
        { id: 'angel.badillo@enviame.io', text: 'Angel Badillo' },
        { id: 'camila.pizarro@enviame.io', text: 'Camila Pizarro' },
        { id: 'christian.osorio@enviame.io', text: 'Christian Osorio' },
        { id: 'gonzalo.bustos@enviame.io', text: 'Gonzalo Bustos' },
        { id: 'leopoldo.rondon@enviame.io', text: 'Leopoldo Rondón' },
        { id: 'luis.tovar@enviame.io', text: 'Luis Tovar' },
        { id: 'operacionpasarela@enviame.io', text: 'Operación Pasarela'},
        { id: 'andrea.armijo@enviame.io', text: 'Andrea Armijo', disabled: true },
        { id: 'joaquin.gonzalez@enviame.io', text: 'Joaquin Gonzalez', disabled: true },
        { id: 'sofia.neira@enviame.io', text: 'Sofia Neira', disabled: true}
      ])
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      carrier: 'getCarrier',
      actionsOpt: 'getActions',
      carrieStatus: 'getCarrierStatus'
    })
  },
  watch: {
    countries() { //Para cuando se actualiza la pagina y el contenido carga antes que el listado de countries
      this.setCountries()
    },
    carrier() {
      this.form = {
        ...this.carrier,
        carrier_status: {
          ...this.carrier.status,
          text: this.carrier.status?.name
        },
        actions: this.carrier.actions?.map(({ name, value }) => ({
          text: name,
          value
        })),
        carrier_manager: this.companyCarrierOptions.filter(({id}) => id === this.carrier.carrier_manager),
        shipper_cancel: this.carrier.shipper_cancel ? [{ id: 1, text: 'Marcar carrier como cancelable' }] : false,
        cancel_delivery: this.carrier.cancel_delivery ? [{ id: 1, text: 'Marcar carrier como envios cancelable' }] : false
      }

      if (this.carrier.logotypes) {
        this.avatar = this.carrier.logotypes[0].url
        this.form.avatar = this.carrier.logotypes[0].url
      }
      this.$store.dispatch('fetchService', { name: 'getClasificationServices', params: { 'id_country': this.form.country.id } })
      this.changeCountryCarrier()
    },
    actionsOpt() {
      const index = this.fields.findIndex(el => el.name === 'actions')
      if (index !== -1) this.fields[index].options = this.actionsOpt
      const arraySetValues = this.carrier.actions?.map(({value}) => Object.values(value)[0])
      this.form.actions = this.actionsOpt.filter(action => arraySetValues?.includes(action.text))
      this.formRenderGenerals++
    },
    carrieStatus() {
      const index = this.fields.findIndex(el => el.name === 'carrier_status')
      if (index !== -1) this.fields[index].options = this.carrieStatus
      this.formRenderGenerals++
    },
    logoFile () {
      if (this.logoFile) {
        if (this.logoFile.size <= 10000000) {
          this.uploadToStorage()
        } else {
          this.avatar = this.form.avatar || null
          this.$alert(this.$t('oversize-image-avatar'))
        }
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre del Courier', containerClass: 'col-sm-12 container-info col-md-3', validation: 'required' },
        { fieldType: 'FieldInput', name: 'code', label: 'Código', containerClass: 'col-sm-12 container-info col-md-3', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'country_carrier', label: 'País', containerClass: 'col-sm-12 container-info col-md-3', validation: 'required', change: this.changeCountryCarrier},
        { fieldType: 'FieldSelect', name: 'carrier_status', label: 'Estado', containerClass: 'col-sm-12 container-info col-md-3'},
        { fieldType: 'FieldSelect', name: 'actions', label: 'Acciones', containerClass: 'col-sm-12 container-info col-md-3', multiple: true, validation: 'required' },
        { fieldType: 'FieldSelect', name: 'carrier_manager', label: 'Courier Manager', useLabel: true, placeholder: 'Courier Manager', clearable: true, validation: 'required', containerClass: 'col-sm-12 container-info col-md-3', options: this.companyCarrierOptions },
        { name: 'logoCourier', useSlot : true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-2' },
        {
          fieldType: 'FieldCheckbox', multiple: true, name: 'cancel_delivery', label: 'Este courier permitira cancelacion de envio', options: [
            { id: 1, text: 'Marcar courier como envios cancelable' }
          ], containerClass: 'col-sm-12 col-md-8 container-info pt-2'
        },
        {
          fieldType: 'FieldCheckbox', multiple: true, name: 'shipper_cancel', label: 'Este courier permitira cancelacion de envio en sus shippers', options: [
            { id: 1, text: 'Marcar courier como cancelable' }
          ], containerClass: 'col-sm-12 col-md-8 container-info pt-2'
        },
        {name: 'titleComercial',  useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1'},
        { fieldType: 'FieldInput', name: 'tradename', label: 'Nombre De la Empresa', containerClass: 'col-sm-12 container-info col-md-3' },
        { fieldType: 'FieldInput', name: 'business_name', label: 'Nombre Comercial', containerClass: 'col-sm-12 container-info col-md-3' },
        { fieldType: 'FieldInput', name: 'business_commercial_code', label: 'WORD_DNI', containerClass: 'col-sm-12 container-info col-md-3', placeholder: 'FORMAT_DNI', validation: 'taxIdValidator:country_carrier' },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1' },
        { fieldType: 'FieldInput', name: 'max_packages', label: 'Cantidad máxima de bultos', containerClass: 'col-sm-12 container-info col-md-3' },
        { fieldType: 'FieldInput', name: 'max_packages_return', label: 'Cantidad máxima de bultos retiros', containerClass: 'col-sm-12 container-info col-md-3' },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1' },
        { fieldType: 'FieldInput', name: 'amount_coverage_insurance', label: 'Monto cobertura sin seguro', containerClass: 'col-sm-12 container-info col-md-3', disabled: true },
        { fieldType: 'FieldInput', name: 'additional_insurance ', label: '% seguro adicional', containerClass: 'col-sm-12 container-info col-md-3', disabled: true },
        { fieldType: 'FieldInput', name: 'compensation_cap_insurance', label: 'Tope indemnización con seguro', containerClass: 'col-sm-12 container-info col-md-3', disabled: true }
      ]
      this.$store.dispatch('fetchService', { name: 'getActions' })
      this.$store.dispatch('fetchService', { name: 'getCarrierStatus' })
      if (this.carrier_id) {
        this.getCarrier()
        this.$store.dispatch('fetchService', { name: 'getServicesCarrier', queryParams: { carrier_id: this.carrier_id } })
      }
      if (this.countries && !!this.countries.length) { //Para cuando se cambia de pantalla dentro del sitio sin actualizar
        this.setCountries()
      }
    },
    changeCountryCarrier(name, value, origin) {
      //Mejora label de codigo comercial, al montar componente y al actualizar pais
      const texts = this.$i18nImport(`${this.form.country_carrier.code.toLowerCase()}.es`)[`${this.form.country_carrier.code.toLowerCase()}.es`]
      const indexDNI = this.fields.findIndex(el => el.name === 'business_commercial_code')
      this.fields[indexDNI].label = texts.WORD_DNI
      this.fields[indexDNI].placeholder = texts.FORMAT_DNI
    },
    getCarrier() {
      this.$store.dispatch('fetchService', { name: 'getCarrier', params: { carrier_id: this.carrier_id } })
    },
    setCountries() {
      const index = this.fields.findIndex(el => el.name === 'country_carrier')
      this.fields[index].options = this.countries.map(value => ({id: value.id, code: value.code, text: value.text}))
      this.formRenderGenerals++
    },
    updateCarrierDetails(data) {
      const queryParams = data
      this.carriersService.callService('updateCarrier', queryParams, { carrier_id: this.carrier_id })
        .then(resp => {
          this.$router.push({ name: 'config-carriers-platforms' })
          this.$success(this.$t('msg-exito-actualizar'))
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-actualizar', { code: err }))
        })
    },
    saveCarrierDetails(data) {
      const queryParams = { ...data }
      this.$store.dispatch('fetchService', { name: 'createCarriers', queryParams, onSuccess: this.successSaveCarrierDetails })
    },
    successSaveCarrierDetails() {
      this.$router.push({ name: 'config-carriers' })
      this.$success(this.$t('msg-exito-guardar'))
    },
    updateOrSaveCarrierDetails(data) {
      const { country_carrier, carrier_status, actions, carrier_manager, ...rest } = data
      const newData = {
        ...rest,
        carrier_manager: carrier_manager.id,
        country_id: country_carrier?.id,
        status_id: carrier_status?.id,
        actions: actions.map(({ value }) => value),
        shipper_cancel: !!data.shipper_cancel[0]?.id,
        cancel_delivery: !!data.cancel_delivery[0]?.id
      }
      if (this.carrier_id) {
        this.updateCarrierDetails(newData)
      } else {
        this.saveCarrierDetails(newData)
      }
    },
    uploadToStorage () {
      this.loading.uploading = true
      const params = { code : this.form.code }
      const data = {
        'logotypes[]': this.logoFile
      }
      this.baseService.callUploadFile('addLogoCourier', data, params)
        .then(response => {
          this.$success(this.$t('msg-exito-guardar'))
        })
        .catch((err) => {
          const errorDetail = err.errors.map(errObj => {
            return `<br><br>${errObj.title}<br>${errObj.detail}`
          })
          this.$alert(`<b>Ocurrió un problema al cargar los datos</b>${errorDetail}`)
          console.error(err)
        }).finally(() => {
          this.loading.uploading = false
        })
    },
    send(e) {
      e.preventDefault()
      this.$refs.formRenderGenerals.checkForm()
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>

<style>

.container-avatar {
  width: 150px;
  height: auto;
}

.overimage {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo-preview {
  width: 260px !important;
  height: 260px !important;
  object-fit: contain;
}

.text-warning {
  color: #ffc107!important; /* This is bootstrap's color for 'warning' */
}


</style>
